import "./style.scss";
import "../TeamModal/style.scss";

export default function TeamMemberCard({ name, img, position, id, openModal }) {
  return (
    <div className="team-member-card">
      <h3 className="team-member-name">{name}</h3>
      <h4 className="team-member-position">{position}</h4>
      <a
        href="#team"
        className="team-member-more"
        onClick={() => openModal(id)}>
        Детальніше
      </a>

      <img src={img} alt="лікар" className="team-member-img" loading="lazy" />
    </div>
  );
}
