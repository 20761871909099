import "./style.scss";
import cross from "../../../assets/images/logos/decorations/cross.svg";

export default function TeamModal({
  member: { name, position, mainInfo, workAchievements, quote },
  closeModal,
}) {
  const mainInfoMarkup = mainInfo.map((el, i) => <li key={i}>{el}</li>);

  const workAchievementsMarkup = workAchievements.map(({ amount, name }, i) => (
    <li key={i}>
      <span className="text-selected">{amount}</span> {name}
    </li>
  ));

  return (
    <div className="team-modal-container">
      <img
        src={cross}
        alt="cross"
        className="cross"
        onClick={closeModal}
        loading="lazy"
      />
      <h2>{name}</h2>
      <h3>{position}</h3>
      <ul className="team-info-list">{mainInfoMarkup}</ul>
      <h3 className="work-achievements">Досягнення у роботі:</h3>
      <ul className="team-achievements-list">{workAchievementsMarkup}</ul>
      <blockquote>&#34;{quote}&#34;</blockquote>
    </div>
  );
}
