import { Fade } from "react-reveal";
import "./style.scss";

export default function SectionTitle({ text, position }) {
  const left = position === "left";
  const right = position === "right";
  return (
    <Fade left={left} right={right}>
      <div className="section-title-wrapper">
        <div className="section-title-line-wrapper">
          <h2 className="section-title">{text}</h2>
          <span className={`section-title-line ${position}`}></span>
        </div>
      </div>
    </Fade>
  );
}
