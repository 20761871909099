import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SectionTitle from "../../SectionTitle";
import case1 from "../../../../assets/images/works/cases/case1.png";
import case2 from "../../../../assets/images/works/cases/case2.png";
import case3 from "../../../../assets/images/works/cases/case3.png";
import case4 from "../../../../assets/images/works/cases/case4.png";
import case5 from "../../../../assets/images/works/cases/case5.png";
import case6 from "../../../../assets/images/works/cases/case6.png";
import case7 from "../../../../assets/images/works/cases/case7.png";
import case8 from "../../../../assets/images/works/cases/case8.png";
import case9 from "../../../../assets/images/works/cases/case9.png";
import case10 from "../../../../assets/images/works/cases/case10.png";
import case11 from "../../../../assets/images/works/cases/case11.png";
import case1webp from "../../../../assets/images/works/cases/case1.webp";
import case2webp from "../../../../assets/images/works/cases/case2.webp";
import case3webp from "../../../../assets/images/works/cases/case3.webp";
import case4webp from "../../../../assets/images/works/cases/case4.webp";
import case5webp from "../../../../assets/images/works/cases/case5.webp";
import case6webp from "../../../../assets/images/works/cases/case6.webp";
import case7webp from "../../../../assets/images/works/cases/case7.webp";
import case8webp from "../../../../assets/images/works/cases/case8.webp";
import case9webp from "../../../../assets/images/works/cases/case9.webp";
import case10webp from "../../../../assets/images/works/cases/case10.webp";
import case11webp from "../../../../assets/images/works/cases/case11.webp";
import arrowL from "../../../../assets/images/logos/decorations/arrow-l-brown.svg";
import arrowR from "../../../../assets/images/logos/decorations/arrow-r-brown.svg";

export default function Cases({ webpSupported }) {
  const cases = [
    {
      name: "ОРТОДОНТИЧНЕ ЛІКУВАННЯ",
      img: webpSupported ? case1webp : case1,
      term: "2,5 роки",
      price: "150 000 грн",
    },
    {
      name: "ЛІКУВАННЯ КАРІЄСУ",
      img: webpSupported ? case2webp : case2,
      term: "1 година",
      price: "3500 грн",
    },
    {
      name: "ОРТОДОНТИЧНЕ ЛІКУВАННЯ",
      img: webpSupported ? case3webp : case3,
      term: "2 роки",
      price: "150 000 грн",
    },
    {
      name: "ОРТОПЕДИЧНЕ ЛІКУВАННЯ, КОРОНКА НА ІМПЛАНТІ",
      img: webpSupported ? case4webp : case4,
      term: "2 тижні",
      price: "14 000 грн",
    },
    {
      name: "ЛІКУВАННЯ КАРІЄСУ",
      img: webpSupported ? case5webp : case5,
      term: "1 година",
      price: "3500 грн",
    },
    {
      name: "ОРТОДОНТИЧНЕ ЛІКУВАННЯ",
      img: webpSupported ? case6webp : case6,
      term: "5 місяців",
      price: "15 000 грн",
    },
    {
      name: "РЕСТАВРАЦІЇ ФОТОПОЛІМЕРНІ, 6 ЗУБІВ ПЕРЕД ОРТОДОНТИЧНИМ ЛІКУВАННЯМ",
      img: webpSupported ? case7webp : case7,
      term: "3 години",
      price: "21 000 грн",
    },
    {
      name: "ОРТОПЕДИЧНЕ ЛІКУВАННЯ (КОРОНКА)",
      img: webpSupported ? case8webp : case8,
      term: "2 тижні",
      price: "110 000 грн",
    },
    {
      name: "ОРТОПЕДИЧНЕ ЛІКУВАННЯ(28 ВІНІРІВ) І ОРТОДОНТИЧНЕ ЛІКУВАННЯ (INVISALIGN)",
      img: webpSupported ? case9webp : case9,
      term: "1,5 роки",
      price: "620 000 грн",
    },
    {
      name: "ОРТОПЕДИЧНЕ ЛІКУВАННЯ (2 КОРОНКИ НА ІМПЛАНТАХ)",
      img: webpSupported ? case10webp : case10,
      term: "1 тиждень",
      price: "28 000 грн",
    },
    {
      name: "ТЕРАПЕВТИЧНЕ ЛІКУВАННЯ",
      img: webpSupported ? case11webp : case11,
      term: "1 година",
      price: "3500 грн",
    },
  ];
  const slides = cases.map(({ name, img, term, price }, i) => (
    <SwiperSlide key={i} className="case-slide">
      <div className="case-card">
        <h4>{name}</h4>
        <img src={img} alt={`приклад послуги: ${name}`} loading="lazy" />
        <p>Тривалість лікування: {term} </p>
        <p>Ціна лікування: {price}</p>
      </div>
    </SwiperSlide>
  ));

  return (
    <section className="container cases-section" id="cases">
      <SectionTitle text="Кейси" position="left" />
      <div className="content">
        <Swiper
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: "-32%",
            },
            460: {
              spaceBetween: "-40%",
            },
            760: {
              spaceBetween: "-50%",
            },
            920: {
              slidesPerView: 3,
              spaceBetween: "-6%",
            },
          }}
          modules={[Autoplay, Navigation]}
          centeredSlides={true}
          navigation={{
            prevEl: ".prev-button.cases",
            nextEl: ".next-button.cases",
          }}>
          {slides}
        </Swiper>
        <div className="slider-arrows">
          <div className="prev-button cases">
            <img src={arrowL} alt="arrow left" loading="lazy" />
          </div>
          <div className="next-button cases">
            <img src={arrowR} alt="arrow right" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
}
