import { Fade } from "react-reveal";
import "./style.scss";
import logo from "../../../assets/images/logos/main-logo.svg";
import instagramLogo from "../../../assets/images/logos/socials/instagram.svg";
import facebookLogo from "../../../assets/images/logos/socials/facebook.svg";

export default function Footer() {
  return (
    <footer className="container">
      <Fade left>
        <a href="#top" className="footer-logo">
          <img src={logo} alt="samko" loading="lazy" />
        </a>
      </Fade>

      <Fade bottom>
        <div className="footer-menu">
          <a href="#aboutUs">про нас</a>
          <a href="#team">команда</a>
          <a href="#services">послуги</a>
          <a href="#price">прайс</a>
          <a href="#cases">кейси</a>
          <a href="#reviews">відгуки</a>
        </div>

        <div className="working-hours">
          <h4 className="footer-title">Режим роботи</h4>
          <div className="schedule-container">
            <p className="schedule-item">
              <span>Понеділок - П'ятниця</span> <span>9:00 - 20:00</span>
            </p>
            <p className="schedule-item">
              <span>Cубота </span> <span>9:00 - 15:00</span>
            </p>
            <p className="schedule-item">
              <span>Неділя </span> <span>ВИХІДНИЙ</span>
            </p>
          </div>
        </div>
        <div className="footer-contacts">
          <h4 className="footer-title">Контакти</h4>
          <div className="contacts-container">
            <a
              href="https://goo.gl/maps/zybsJ4XcdeaYtr6o6"
              className="contact-item address">
              Київ, вул Бульварно-Кудрявська 33Г
            </a>
            <a href="tel:+38 093 901 04 54" className="contact-item phone">
              +38 093 901 04 54
            </a>
            <a
              href="mailto: dentist.samko@gmail.com"
              className="contact-item email">
              dentist.samko@gmail.com
            </a>
          </div>
        </div>
      </Fade>

      <Fade right>
        <div className="footer-socials">
          <a
            href="https://instagram.com/dentist.samko?igshid=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noreferrer">
            <img src={instagramLogo} alt="instagram" loading="lazy" />
          </a>
          <a
            href="https://www.facebook.com/dentist.samko.kyiv"
            target="_blank"
            rel="noreferrer">
            <img src={facebookLogo} alt="facebook" loading="lazy" />
          </a>
        </div>
      </Fade>
    </footer>
  );
}
