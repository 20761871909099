import { Fade } from "react-reveal";
import "./style.scss";

export default function ServiceCard({ title, text, img, list = [] }) {
  const listMarkup =
    list.length === 0 ? (
      ""
    ) : (
      <ul>
        {list.map((listItem, i) => (
          <li key={i}>{listItem}</li>
        ))}
      </ul>
    );
  return (
    <Fade bottom>
      <div className="service-card">
        <h3>{title}</h3>
        <p>{text}</p>
        <img src={img} alt="teeth" loading="lazy" />
        {listMarkup}
      </div>
    </Fade>
  );
}
