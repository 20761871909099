import { Fade } from "react-reveal";
import "./style.scss";
import gift from "../../../../assets/images/logos/decorations/gift.svg";

export default function Hero() {
  return (
    <Fade top>
      <section className="container hero-section">
        <a href="#register" className="gift">
          <img src={gift} alt="подарунок" />
        </a>
      </section>
    </Fade>
  );
}
