import "./App.scss";
import Footer from "./components/simple/Footer";
import Header from "./components/simple/Header";
import About from "./components/simple/sections/About";
import OurTeam from "./components/simple/sections/OurTeam";
import Benefits from "./components/simple/sections/Benefits";
import Cases from "./components/simple/sections/Cases";
import Hero from "./components/simple/sections/Hero";
import Prices from "./components/simple/sections/Prices";
import Reviews from "./components/simple/sections/Reviews";
import Services from "./components/simple/sections/Services";
import RegisterForm from "./components/smart/sections/RegisterForm/RegisterForm.js";
import { useEffect, useState } from "react";
import isWebPSupported from "./functions/isWebpSupported";

function App() {
  const [webPSupport, setWebPSupport] = useState(false);

  useEffect(() => {
    if (isWebPSupported()) {
      document.body.classList.add("webp-support");
      setWebPSupport(true);
    } else {
      setWebPSupport(false);
    }
    console.clear();
  }, []);

  return (
    <>
      <div id="top"></div>
      <Header />
      <Hero />
      <Benefits webpSupported={webPSupport}/>
      <About webpSupported={webPSupport}/>
      <OurTeam webpSupported={webPSupport} />
      <Services webpSupported={webPSupport} />
      <Prices />
      <Cases webpSupported={webPSupport} />
      <Reviews />
      <RegisterForm />
      <Footer />
    </>
  );
}

export default App;
