import "./style.scss";
import SectionTitle from "../../SectionTitle";
import owners from "../../../../assets/images/team/owners.png";
import ownersWebp from "../../../../assets/images/team/owners.webp";
import { useState } from "react";
import { Fade } from "react-reveal";

export default function About({ webpSupported }) {
  const [isTextOpen, setTextOpen] = useState(false);

  const handleTextToggle = () => setTextOpen(!isTextOpen);

  return (
    <section className="container about-section" id="aboutUs">
      <SectionTitle text="Про нас" position="left" />
      <div className="content">
        <Fade right>
          <div className="img-container">
            <img
              src={webpSupported ? ownersWebp : owners}
              alt="Дружина і чоловік"
            />
          </div>
        </Fade>
        <Fade left>
          <div>
            <p className={`text ${isTextOpen ? "full" : ""}`}>
              <strong>SAMKO family dental studio</strong> — унікальне місце, де
              ми не лише дбаємо про ваші посмішки, а й створюємо щасливі спогади
              та приємні емоції.  Наша стоматологія — це синергія турботи,
              піклування та взаємної співпраці лікаря та пацієнта. Ми
              переконані, що результат буде тоді, коли зусилля професіонала
              поєднанні із бажанням та позитивною енергією клієнта. <br />{" "}
              <br /> <b>Як все починалося?</b> А починалося все із любові. У
              першу чергу до стоматології. А також із великого кохання, яке
              переросло у сімейну справу та створення справжньої dream team.
              <br /> <br /> <b>Я — Юлія Самко</b>, лікар-стоматолог, ортодонт і
              засновниця бренду SAMKO family dental studio. Усе життя мріяла про
              команду класних спеціалістів, де кожен виконує свою роботу
              професійно та з турботою до пацієнтів. І ви є свідками втілення
              цієї мрії. <br /> <br />{" "}
              <b>Мій супутник по життю — Самко Олексій Андрійович</b>,
              лікар-стоматолог, ортопед, терапевт. Справжній майстер своєї
              справи, талановита людина, що прагне все зробити  ідеально.  Ми
              пліч-о-пліч будували фундамент, на якому тепер стоїть наше
              творіння, яке допомагає, рятує, лікує.  SAMKO family dental studio
              — історія нашої родини, яка стає початком втілення вашої мрії.
            </p>
            <button
              type="button"
              className="toggle-btn"
              onClick={handleTextToggle}>
              {isTextOpen ? "Згорнути" : "ЧИТАТИ ПОВНІСТЮ"}
            </button>
          </div>
        </Fade>
      </div>
    </section>
  );
}
