import { useState } from "react";
import { Fade } from "react-reveal";
import mainLogo from "../../../assets/images/logos/main-logo.svg";
import phoneImg from "../../../assets/images/logos/info/phone.svg";
import instagramLogo from "../../../assets/images/logos/socials/instagram.svg";
import facebookLogo from "../../../assets/images/logos/socials/facebook.svg";
import "./style.scss";

export default function Header() {
  const [checked, setChecked] = useState(false);

  const toggleMenu = () => setChecked(!checked);

  return (
    <header>
      <div className="container">
        <input
          type="checkbox"
          id="menu"
          checked={checked}
          onChange={toggleMenu}
        />

        <Fade left>
          <label htmlFor="menu" className="burger">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </Fade>

        <div className="navbar-menu" onClick={toggleMenu}>
          <Fade top>
            <a href="#aboutUs">про нас</a>
            <a href="#team">команда</a>
            <a href="#services">послуги</a>
            <a href="#price">прайс</a>
            <a href="#cases">кейси</a>
            <a href="#reviews">відгуки</a>
          </Fade>
          <Fade right>
            <div className="contacts">
              <a href="https://goo.gl/maps/zybsJ4XcdeaYtr6o6">
                Київ, вул Бульварно-Кудрявська 33Г
              </a>
              <a href="tel:+380939010454">+38 093 901 04 54</a>
            </div>
          </Fade>

          <div className="socials">
            <a
              href="https://instagram.com/dentist.samko?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer">
              <img src={instagramLogo} alt="instagram" />
            </a>
            <a
              href="https://www.facebook.com/dentist.samko.kyiv"
              target="_blank"
              rel="noreferrer">
              <img src={facebookLogo} alt="facebook" />
            </a>
          </div>
        </div>
        <Fade left>
          <a href="#top">
            <img src={mainLogo} alt="samko family dental studio" />
          </a>
        </Fade>
        <Fade right>
          <a href="tel:+380939010454" className="header-phone">
            <img src={phoneImg} alt="phone" />
          </a>
        </Fade>
      </div>
    </header>
  );
}
