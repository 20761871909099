import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SectionTitle from "../../SectionTitle";
import stars from "../../../../assets/images/logos/decorations/stars.svg";
import arrowL from "../../../../assets/images/logos/decorations/arrow-l-white.svg";
import arrowR from "../../../../assets/images/logos/decorations/arrow-r-white.svg";

export default function Reviews() {
  const reviews = [
    {
      name: "Ніна",
      review:
        "Моя мрія довжиною у 2 роки здійснилася. Тепер я маю рівні зуби і +100 до впевненості в собі. Я безмежно вдячна Юлії Петрівні та всій команді за дбайливе ставлення, високий професіоналізм і врахування усіх моїх побажань. Кожний прийом був ніби зустріч із добрими друзями: завжди тепло, затишно і комфортно. Успіхів вам! Ви неймовірні.",
    },
    {
      name: "Анна",
      review:
        "Після лікування зубів у клініці SAMKO family dental studio у мене більше немає сумнівів, як має виглядати справжній професіонал своєї справи. Дякую Олексію Андрійовичу! Зручно, швидко і супер якісно. Задоволений на 100%",
    },
    {
      name: "марія",
      review:
        "Ми звернулися в клініку з проблемою скупчення зубів у дитини. Дуже класно, що Юлія Петрівна все пояснила, ніби розклала по поличках нашу проблему і запропонувала шляхи вирішення. Тепер у донечки будуть рівні та красиві зуби. Дякую вам величезне!",
    },
    {
      name: "олена",
      review:
        "До Юлії Петрівни я прийшла із запитом — хочу рівні зуби та красиву посмішку. Вона, як професіонал пояснила всі тонкощі роботи над моєю ціллю, проговорила поетапно наш план лікування та показала прогнозований результат. Зараз ми в процесі створення краси, але вже тепер я можу сказати, що щаслива вибором клініки на всі 100%.",
    },
    {
      name: "віталій",
      review:
        "Коли в мене друзі запитують, де полікувати зуби, я завжди раджу SAMKO family dental studio. Бо тут є найголовніше, як на мене, — увага до потреб пацієнта, високий професіоналізм лікарів та якісний сервіс.",
    },
    {
      name: "катерина",
      review:
        "Ми звернулися в клініку з проблемою скупчення зубів у дитини. Дуже класно, що Юлія Петрівна все пояснила, ніби розклала по поличках нашу проблему і запропонувала шляхи вирішення. Тепер у донечки будуть рівні та красиві зуби. Дякую вам величезне!",
    },
    {
      name: "олег",
      review:
        "Завжди дуже боявся стоматологів, тому постійно відкладав візити. Після візиту до Олексія Андрійовича почуваюся, ніби після сесії у психолога)) це круто, що є спеціалісти, які не просто лікують зуби, а й зцілюють від страхів. Моя вдячність вам, пане лікарю!",
    },
    {
      name: "ксенія",
      review:
        "Дякую команді SAMKO family dental studio за уважне та турботливе ставлення до моєї мами, яку я привела у клініку для протезування зубів. Сподобалося все — сервіс, ставлення персоналу, високий рівень послуг та оперативність виконання. Ви неймовірні! Бажаю вам лише вдячних та чемних пацієнтів, а також розвитку, масштабування і грандіозних проектів.",
    },
    {
      name: "олексій",
      review:
        "Спасибі вам, що так оперативно прийняли із гострим болем і вирішили мою проблему. Особливо хочу подякувати адміністратору Олександрі за відповіді на всі мої запитання, турботливе ставлення та чарівну посмішку)",
    },
  ];

  const slides = reviews.map(({ name, review }, i) => (
    <SwiperSlide key={i}>
      <div className="user-slide">
        <div className="user-profile">
          <p className="user-letter">{name[0]}</p>
          <img
            src={stars}
            alt="зірки"
            className="user-estimate"
            loading="lazy"
          />
        </div>
        <div className="user-text">
          <h4 className="user-name">{name}</h4>
          <p className="user-review">{review}</p>
        </div>
      </div>
    </SwiperSlide>
  ));

  return (
    <section className="container reviews-section" id="reviews">
      <SectionTitle text="Відгуки" position="right" />
      <div className="content">
        <Swiper
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          centeredSlides={true}
          navigation={{
            prevEl: ".prev-button.reviews",
            nextEl: ".next-button.reviews",
          }}
          spaceBetween={"-23%"}>
          {slides}
        </Swiper>
        <div className="slider-arrows">
          <div className="prev-button reviews">
            <img src={arrowL} alt="arrow left" loading="lazy" />
          </div>
          <div className="next-button reviews">
            <img src={arrowR} alt="arrow right" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
}
