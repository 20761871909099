import "./style.scss";
import SectionTitle from "../../SectionTitle";
import PriceItem from "../../PriceItem";

export default function Prices() {
  const prices = [
    {
      name: "КОНСУЛЬТАЦІЯ",
      price: "від 500 грн",
    },
    {
      name: "ПРОФЕСІЙНА ГІГІЄНА",
      price: "від 2450 грн",
    },
    {
      name: "ЛІКУВАННЯ КОРЕННЕВОЇ СИСТЕМИ",
      price: "від 3000 грн",
    },
    {
      name: "КЕРАМІЧНА РЕСТАВРАЦІЯ",
      price: "від 10 000 грн",
    },
    {
      name: "ВИДАЛЕННЯ ЗУБІВ",
      price: "від 2 000 грн",
    },
    {
      name: "ІМПЛАНТАЦІЯ",
      price: "від 12 000 грн",
    },
    {
      name: "ЛІКУВАННЯ КАРІЄСУ",
      price: "від 2200 грн",
    },
  ];

  const priceList = prices.map(({ name, price }, i) => (
    <PriceItem name={name} price={price} key={i} />
  ));

  return (
    <section className="container prices-section" id="price">
      <SectionTitle text="Прайс" position="right" />
      <ul className="prices-list">{priceList}</ul>
    </section>
  );
}
