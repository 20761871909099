import "./style.scss";

export default function ActiveTeamMember({
  member: { name, img, position, quote, mainInfo, workAchievements },
}) {
  const mainInfoMarkup = mainInfo.map((el, i) => <li key={i}>{el}</li>);

  const workAchievementsMarkup = workAchievements.map(({ amount, name }, i) => (
    <li key={i}>
      <span className="text-selected">{amount}</span> {name}
    </li>
  ));

  return (
    <div className="team-member-card active-slide">
      <h3 className="team-member-name">{name}</h3>
      <h4 className="team-member-position">{position}</h4>

      <div className="active-member-info">
        <ul className="team-info-list">{mainInfoMarkup}</ul>
        <h3 className="work-achievements">Досягнення у роботі:</h3>
        <ul className="team-achievements-list">{workAchievementsMarkup}</ul>
        <blockquote>&#34;{quote}&#34;</blockquote>
      </div>

      <img src={img} alt="лікар" className="team-member-img" loading="lazy" />
    </div>
  );
}
