import { Fade } from "react-reveal";
import "./style.scss";

export default function PriceItem({ name, price }) {
  return (
    <li className="prices-list-item">
      <Fade left>
        <span>{name}</span>
      </Fade>
      <Fade right>
        <span>{price}</span>
      </Fade>
    </li>
  );
}
