import "./style.scss";
import { useState } from "react";
import SectionTitle from "../../SectionTitle";
import ServiceCard from "../../ServiceCard";
import tooth1 from "../../../../assets/images/logos/teeth/tooth-1.svg";
import tooth2 from "../../../../assets/images/logos/teeth/tooth-2.png";
import tooth2webp from "../../../../assets/images/logos/teeth/tooth-2.webp";
import tooth3 from "../../../../assets/images/logos/teeth/tooth-3.svg";
import tooth4 from "../../../../assets/images/logos/teeth/tooth-4.svg";
import tooth5 from "../../../../assets/images/logos/teeth/tooth-5.svg";

export default function Services({ webpSupported }) {
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isServicesVisible, setServicesVisible] = useState(false);

  const handleServicesToggle = () => {
    setServicesOpen(!isServicesOpen);
    setTimeout(() => setServicesVisible(!isServicesOpen), 500);
  };

  const services = [
    {
      title: "ДИТЯЧА СТОМАТОЛОГІЯ",
      text: "Здійснюємо лікування дітей раннього віку, а саме — каріозні та некаріозні ураження зуба, лікування кореневих каналів та встановлення коронок при потребі. Проводимо уроки гігієни для наймолодших пацієнтів, а також ретельно спостерігаємо за ортодонтичним статусом під час оглядів та лікування.",
      img: tooth1,
    },
    {
      title: "ТЕРАПІЯ",
      text: "Здійснюємо лікування зубів з каріозними та некаріозними ураженнями, кореневих каналів зуба, а також — тканин, які оточують зуб.",
      img: webpSupported ? tooth2webp : tooth2,
    },
    {
      title: "ХІРУРГІЯ",
      text: "Виконуємо видалення різної складності від легких до складних та атипових. Проводимо імплантацію різними видами імплантів середнього та високого сегменту з наступним протезуванням.",
      img: tooth4,
    },
    {
      title: "ПРОТЕЗУВАННЯ",
      text: "Виконуємо різноманітні види реставраційних робіт: вініри, вкладки, коронки, культьові вкладки, коронки на імплантах. Працюємо з незнімними та знімними конструкціями у порожнині рота, що відновлюють зубні ряди.",
      img: tooth3,
    },
    {
      title: "ортодонтія",
      text: "Проводимо різноманітні види лікування:",
      img: tooth5,
      list: [
        "дитяча ортодонтія — знімні та незнімні апаратури для розширення щелепи;",
        "дорослі пацієнти — лікування суглобових патологій з використанням шин;",
        "вирівнювання зубного ряду за допомогою брекетів та елайнерів для пацієнтів будь-якого віку.",
      ],
    },
  ];

  const servicesListMarkup = services.map(({ title, text, img, list }, i) => (
    <ServiceCard key={i} title={title} text={text} img={img} list={list} />
  ));

  return (
    <section className="container services-section" id="services">
      <SectionTitle text="Види послуг" position="left" />
      <div
        className={`services-list ${isServicesOpen ? "full" : ""} ${
          isServicesVisible ? "visible" : ""
        }`}>
        {servicesListMarkup}
      </div>
      <div className="toggle-btn-container">
        <button
          type="button"
          className={`toggle-btn services ${isServicesOpen ? "open" : ""}`}
          onClick={handleServicesToggle}>
          {isServicesOpen ? "Згорнути" : "Всі послуги"}
        </button>
      </div>
    </section>
  );
}
