import { Fade } from "react-reveal";

import "./style.scss";

export default function BenefitCard({ title, text, number }) {
  return (
    <Fade bottom cascade>
      <div className="benefit-card-item">
        <div className="benefit-card-title-wrapper">
          <h3>{title}</h3>
          <span className="benefit-card-number">
            {number < 10 ? `0${number}` : number}
          </span>
        </div>
        <p>{text}</p>
      </div>
    </Fade>
  );
}
