import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import SectionTitle from "../../SectionTitle";
import TeamMemberCard from "../../TeamMemberCard";
import "./style.scss";
import team1 from "../../../../assets/images/team/Юлія Петрівна.png";
import team2 from "../../../../assets/images/team/Марія Вікторівна.png";
import team3 from "../../../../assets/images/team/Артем Володимирович.png";
import team4 from "../../../../assets/images/team/Олександра В’ячеславівна.png";
import team5 from "../../../../assets/images/team/Олексій Андрійович.png";
import team6 from "../../../../assets/images/team/Олександр Вікторович.png";
import team1webp from "../../../../assets/images/team/Юлія Петрівна.webp";
import team2webp from "../../../../assets/images/team/Марія Вікторівна.webp";
import team3webp from "../../../../assets/images/team/Артем Володимирович.webp";
import team4webp from "../../../../assets/images/team/Олександра В’ячеславівна.webp";
import team5webp from "../../../../assets/images/team/Олексій Андрійович.webp";
import team6webp from "../../../../assets/images/team/Олександр Вікторович.webp";
import arrowImg from "../../../../assets/images/logos/decorations/arrow-team.svg";
import arrowImgBottom from "../../../../assets/images/logos/decorations/arrow-team-bottom.svg";
import { useState, useEffect } from "react";
import TeamModal from "../../TeamModal";
import ActiveTeamMember from "../../ActiveTeamMember";

export default function OurTeam({ webpSupported }) {
  const team = [
    {
      name: "Юлія Петрівна",
      position: "Лікар куратор, стоматолог-ортодонт",
      img: webpSupported ? team1webp : team1,
      mainInfo: [
        "Закінчила Київський Медичний Коледж №4 за спеціальністю стоматологія ортопедична.",
        "У 2008-2013 роках навчалася в КМУ УАНМ (спеціальність лікар-стоматолог).",
        "На базі цього ж навчального закладу пройшла інтернатуру.",
        "У 2016 році отримала спеціалізацію “Ортодонтія” у Харківській  медичній академії післядипломної освіти.",
        "Понад 50 пройдених курсів, майстер-класів та конференцій з різних напрямків стоматології.",
      ],
      workAchievements: [
        {
          amount: "500+",
          name: "консультацій",
        },
        {
          amount: "400+",
          name: "пломб",
        },
        {
          amount: "100+",
          name: "коронок",
        },
        {
          amount: "200+",
          name: "гарних посмішок",
        },
        {
          amount: "10",
          name: "наукових робіт",
        },
        {
          amount: "15",
          name: "лекцій",
        },
      ],
      quote:
        "Медицина — це мистецтво. Мистецтво мудрих лікарів, що прагнуть допомогти хворому, а не наповнити кишені",
    },
    {
      name: "Марія Вікторівна",
      position: "Лікар стоматолог",
      img: webpSupported ? team2webp : team2,
      mainInfo: [
        "Закінчила Національний медичний університет імені О.О.Богомольця. На базі цього ж навчального закладу пройшла інтернатуру у 2021-2023 роках",
        "Пройшла курси з практичної пародонтології, ортодонтії та інтенсив по дитячій стоматології.",
      ],
      workAchievements: [
        {
          amount: "100+",
          name: "чисток",
        },
        {
          amount: "7000+",
          name: "прийнятих пацієнтів з лікарем в 4 руки",
        },

        {
          amount: "1000+",
          name: "людей навчила користуватися щіткою, пастою і флосом",
        },
      ],
      quote: "Створювати те, що робить життя людей легшим і щасливішим",
    },
    {
      name: "Артем Володимирович",
      position: "Старший асистент",
      img: webpSupported ? team3webp : team3,
      mainInfo: [
        "Закінчив медичний фаховий коледж Національного медичного університету імені О.О. Богомольця",
        "Закінчив Національний медичний університет імені О.О. Богомольця за спеціальністю “Стоматологія ортопедична",
      ],
      workAchievements: [
        {
          amount: "500+",
          name: "виготовлених моделей зубів",
        },
        {
          amount: "100+",
          name: "модельованих зубів",
        },

        {
          amount: "250+",
          name: "годин роботи асистентом в 4 руки",
        },
      ],
      quote: "Йти вперед, не зупиняючись! Хоч маленькими кроками, але йти!",
    },

    {
      name: "Олександра В’ячеславівна",
      position: "Старший адміністратор",
      img: webpSupported ? team4webp : team4,
      mainInfo: [
        "«Соціальна робота» бакалаврат в НУБіП України, наразі здобуває ступінь магістра.",
        "«Міжнародні відносини» заочна форма навчання в НУБіП України, бакалаврат.",
        "Курси з SMM в «Digital Marketing Group”",
      ],
      workAchievements: [
        {
          amount: "1000+",
          name: "записаних пацієнтів",
        },
        {
          amount: "500+",
          name: "оформлених документів для клініки",
        },

        {
          amount: "400+",
          name: "днів роботи як SMM-спеціаліст",
        },
      ],
      quote:
        "Лікуй зуби в дитинстві, за рахунок батьків — це зекономить тобі гроші, коли виростеш",
    },

    {
      name: "Олексій Андрійович",
      position: "Головний лікар клініки, стоматолог-терапевт-ортопед",
      img: webpSupported ? team5webp : team5,
      mainInfo: [
        "Здобув спеціальність ортопедична стоматологія в Київському Медичному Коледжі.",
        "Опанував спеціальність лікар-стоматолог у КМУ УАНМ.",
        " На базі цього ж навчального закладу пройшов магістратуру.",
        " У 2015 році освоїв спеціалізацію з терапевтичної стоматології у НМАПО імені Шупика.",
        "  А в 2018 році — спеціалізацію по ортопедичній стоматології при Українській військово-медичній академії.",
        "Пройшов 7 курсів, серед яких: Курс “Клінічні аспекти в ендодонтичному лікуванні”, “Препарування під вініри та коронки”, “Патологічне стирання зубів” та інші.",
      ],
      workAchievements: [
        {
          amount: "1000+",
          name: "пролікованих зубів",
        },
        {
          amount: "200+",
          name: "естетичних реставрацій",
        },

        {
          amount: "500+",
          name: "встановлених коронок",
        },
        {
          amount: "750+",
          name: "встановлено вінірів",
        },
      ],
      quote:
        "Знайти лікаря, не маючи волі до лікування, подібне до того, як ловити рибу в калюжі",
    },

    {
      name: "Олександр Вікторович",
      position: "Лікар стоматолог-хірург другої категорії",
      img: webpSupported ? team6webp : team6,
      mainInfo: [
        "Освіту здобув в Українській медичній стоматологічній академії м. Полтава",
        "Інтернатуру закінчив в НМУ ім. О.О. Богомольця",
        "Опанував спеціалізацію на кафедрі хірургічної стоматології НМАПО ім. П.Л.Шупика",
        "А у 2018 р. йому присвоїли другу кваліфікаційну категорію за фахом «Хірургічна стоматологія».",
        "Відтоді невпинно вдосконалює навички і набуває нові професійні знання, на його рахунку більше, ніж 25 курсів, лекцій та  семінарів.",
      ],
      workAchievements: [
        {
          amount: "4000+",
          name: "видалених зубів мудрості",
        },
        {
          amount: "5500+",
          name: "встановлених імплантів",
        },

        {
          amount: "1500+",
          name: "операцій",
        },
      ],
      quote:
        "Треба ставитись до кожного пацієнта так, ніби в кріслі сидить ваша рідна мати",
    },
  ];

  const [toggleModal, setToggleModal] = useState(false);
  const [member, setMember] = useState(team[0]);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = (id) => {
    setMember(team[id]);
    if (windowSize.width <= 920) {
      setToggleModal(true);
      document.body.classList.add("open-modal");
    }
  };

  const closeModal = () => {
    setToggleModal(false);
    document.body.classList.remove("open-modal");
  };

  const modal = toggleModal ? (
    <TeamModal member={member} closeModal={closeModal} />
  ) : (
    ""
  );

  const slides = team.map(
    ({ name, img, position, mainInfo, workAchievements, quote }, i) => (
      <SwiperSlide key={i} className="case-slide">
        <TeamMemberCard
          name={name}
          img={img}
          position={position}
          id={i}
          openModal={openModal}
          mainInfo={mainInfo}
          workAchievements={workAchievements}
          quote={quote}
        />
      </SwiperSlide>
    )
  );
  return (
    <>
      <div className="overlay" onClick={closeModal}></div>
      <section className="container team-section" id="team">
        {modal}
        <SectionTitle text="НАША КОМАНДА" position="right" />
        <div className="team-slider-container">
          <ActiveTeamMember member={member} />
          <Swiper
            direction={"vertical"}
            modules={[Autoplay, Navigation]}
            className="teamSwiper"
            navigation={{
              prevEl: ".prev-button.team",
              nextEl: ".next-button.team",
            }}
            slidesPerView={2}
            loop={true}
            autoplay={
              !toggleModal
                ? {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }
                : false
            }
            spaceBetween={30}
            breakpoints={{
              920: {
                slidesPerView: 2,
                spaceBetween: 80,
              },
            }}>
            {slides}

            <img
              src={arrowImg}
              alt="arrow"
              className="next-button team"
              loading="lazy"
            />
            <img
              src={arrowImgBottom}
              alt="arrow"
              className="prev-button team"
              loading="lazy"
            />
          </Swiper>
        </div>
      </section>
    </>
  );
}
