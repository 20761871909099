import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import BenefitCard from "../../BenefitCard";
import SectionTitle from "../../SectionTitle";
import "./style.scss";
import companyImg1 from "../../../../assets/images/logos/companies/company-1.png";
import companyImg2 from "../../../../assets/images/logos/companies/company-2.png";
import companyImg3 from "../../../../assets/images/logos/companies/company-3.png";
import companyImg4 from "../../../../assets/images/logos/companies/company-4.png";
import companyImg5 from "../../../../assets/images/logos/companies/company-5.png";
import companyImg6 from "../../../../assets/images/logos/companies/company-6.png";
import companyImg7 from "../../../../assets/images/logos/companies/company-7.png";
import companyImg8 from "../../../../assets/images/logos/companies/company-8.png";
import companyImg9 from "../../../../assets/images/logos/companies/company-9.png";
import companyImg10 from "../../../../assets/images/logos/companies/company-10.png";
import companyImg11 from "../../../../assets/images/logos/companies/company-11.png";
import companyImg12 from "../../../../assets/images/logos/companies/company-12.png";
import companyImg13 from "../../../../assets/images/logos/companies/company-13.png";
import companyImg14 from "../../../../assets/images/logos/companies/company-14.png";
import companyImg1webp from "../../../../assets/images/logos/companies/company-1.webp";
import companyImg2webp from "../../../../assets/images/logos/companies/company-2.webp";
import companyImg3webp from "../../../../assets/images/logos/companies/company-3.webp";
import companyImg4webp from "../../../../assets/images/logos/companies/company-4.webp";
import companyImg5webp from "../../../../assets/images/logos/companies/company-5.webp";
import companyImg6webp from "../../../../assets/images/logos/companies/company-6.webp";
import companyImg7webp from "../../../../assets/images/logos/companies/company-7.webp";
import companyImg8webp from "../../../../assets/images/logos/companies/company-8.webp";
import companyImg9webp from "../../../../assets/images/logos/companies/company-9.webp";
import companyImg10webp from "../../../../assets/images/logos/companies/company-10.webp";
import companyImg11webp from "../../../../assets/images/logos/companies/company-11.webp";
import companyImg12webp from "../../../../assets/images/logos/companies/company-12.webp";
import companyImg13webp from "../../../../assets/images/logos/companies/company-13.webp";
import companyImg14webp from "../../../../assets/images/logos/companies/company-14.webp";

export default function Benefits({ webpSupported }) {
  const benefits = [
    {
      title: "Сучасні технології",
      text: "Не йти в ногу з сучасністю, означає йти в іншому напрямку — переконання, на якому базується основна цінність нашої команди. У своїй практиці ми використовуємо тільки найсучасніші методи виготовлення наших робіт. А саме, 3D-технології, які дозволяють зекономити час, максимально точно спрогнозувати бажаний результат лікування та передбачити можливі проблеми та нюанси.",
    },
    {
      title: "Найкращі матеріали",
      text: "Матеріали, які використовує команда SAMKO family dental studio, — це частина іміджу. Адже як би ідеально не була виконана робота, все може перекреслити неякісний матеріал. Тому ми обираємо найкращий, той, що пройшов сертифікацію та має доказову базу.",
    },
    {
      title: "Командна робота",
      text: "Нашою головною цінністю є здоров’я людини в цілому, а не лише одного зуба. Тому ми ретельно проводимо діагностику, аналізуємо ситуацію та пропонуємо покрокову стратегію лікування. Кожен з команди готовий прислухатися до думки колеги, погляду зі сторони та професійної поради. Впевнені, що класний результат можна отримати тільки тоді, коли двоє не лише дивляться в одному напрямку, а й бачать одне і те ж",
    },
  ];

  const benefitsItems = benefits.map(({ title, text }, i) => (
    <BenefitCard title={title} text={text} number={i + 1} key={i} />
  ));

  const companyImagesPNG = [
    companyImg1,
    companyImg2,
    companyImg3,
    companyImg4,
    companyImg5,
    companyImg6,
    companyImg7,
    companyImg8,
    companyImg9,
    companyImg10,
    companyImg11,
    companyImg12,
    companyImg13,
    companyImg14,
  ];

  const companyImagesWEBP = [
    companyImg1webp,
    companyImg2webp,
    companyImg3webp,
    companyImg4webp,
    companyImg5webp,
    companyImg6webp,
    companyImg7webp,
    companyImg8webp,
    companyImg9webp,
    companyImg10webp,
    companyImg11webp,
    companyImg12webp,
    companyImg13webp,
    companyImg14webp,
  ];

  const companyImages = webpSupported ? companyImagesWEBP : companyImagesPNG;

  const companyImagesMarkup = companyImages.map((img, i) => (
    <SwiperSlide key={i}>
      <img src={img} alt="company" draggable="false" loading="lazy" />
    </SwiperSlide>
  ));

  return (
    <section className="container benefits-section">
      <SectionTitle text="НАШІ ПЕРЕВАГИ" position="right" />
      <div className="benefits-list">{benefitsItems}</div>
      <Swiper
        className="slider"
        spaceBetween={50}
        allowTouchMove={false}
        loop={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 3,
          },
          540: {
            slidesPerView: 4,
          },
          920: {
            slidesPerView: 5,
          },
        }}
        modules={[Autoplay]}>
        {companyImagesMarkup}
      </Swiper>
    </section>
  );
}
