import { useState } from "react";
import { Fade } from "react-reveal";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import "./style.scss";
import check from "../../../../assets/images/logos/decorations/check.svg";

const RegisterForm = () => {
  const [formStatus, setFormStatus] = useState(
    localStorage.getItem("isUserAlreadySumbitForm") || false
  );
  const [checkboxStatus, setCheckboxStatus] = useState(true);

  const handleChange = () => {
    setCheckboxStatus(!checkboxStatus);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formCheckbox = document.getElementById("formCheckbox");
    if (!formCheckbox.checked) {
      return;
    }
    setFormStatus(true);

    const form = document.getElementById("registerForm");
    const formData = new FormData(form);

    // to prevent formula Error inside google sheets cell
    formData.set("Phone", formData.get("Phone").replace("+", "'+"));

    axios
      .post(
        "https://script.google.com/macros/s/AKfycbzA2oVMtqNVVvZ1I2mRjD8Z9Og2kQ9xGK-tPB3RATWOquD8WuMCkI41t93D3Z-TX0v2UA/exec",
        formData
      )
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        form.reset();
        localStorage.setItem("isUserAlreadySumbitForm", true);
      });
  };

  const form = !formStatus ? (
    <div>
      <h2 className="register-title">-10% на перший прийом при реєстрації!</h2>
      <form onSubmit={handleSubmit} id="registerForm" className="register-form">
        <input type="text" name="Name" placeholder="ПІБ" required />
        <PhoneInput
          inputClass="phone-input"
          showDropdown={false}
          country="ua"
          placeholder="+38 (0__) ___-__-__"
          inputProps={{
            name: "Phone",
            required: true,
            min: 10,
          }}
        />
        <input type="email" name="Email" placeholder="E-mail" required />
        <label className="form-checkbox">
          <input
            type="checkbox"
            id="formCheckbox"
            onChange={handleChange}
            defaultChecked={true}
          />
          Я згоден наобробку персональних даних
        </label>
        <div>
          <button
            type="submit"
            id="submitBtn"
            className="submit-btn"
            disabled={formStatus || !checkboxStatus}>
            Зареєструватись
          </button>
        </div>
      </form>
    </div>
  ) : (
    <div className="success-register">
      <img src={check} alt="check mark" loading="lazy" />
      <h2>Реєстрація успішна!</h2>
    </div>
  );

  return (
    <section id="register" className="container register-section">
      <Fade bottom>
        <div className="register-container">{form}</div>
      </Fade>
    </section>
  );
};

export default RegisterForm;
